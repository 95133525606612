import 'bootstrap/dist/css/bootstrap.css'
import { createApp } from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import router from './router'

import i18n from './locales/i18n'

import './assets/global.css'
library.add(fas, fab, far)

import axios from '@/functions/axios-instance'    

import { initStore } from '@/stores/store'
initStore()

import { useStore } from '@/stores/store'
const store = useStore()

createApp(App)
    .provide('axios', axios)
    .provide('store', store)
    .use(i18n)
    .use(router)
    .component('fa', FontAwesomeIcon)
    .mount('#app') 

import 'bootstrap/dist/js/bootstrap.js'


// vuejs 3 + typescript + router + babel
// -------------------------------------
// npm i axios
// npm i bootstrap
// npm i popperjs@/core - Not working in v-for
// npm i --save @fortawesome/vue-fontawesome@prerelease
// npm i --save @fortawesome/fontawesome-svg-core
// npm i --save @fortawesome/free-solid-svg-icons
// npm i --save @fortawesome/free-brands-svg-icons
// npm i --save @fortawesome/free-regular-svg-icons
// sudo 
// npm uninstall @fortawesome/xxx one by one > npm install > npm reinstall back one by one when upgrade having issues

// npm i vue3-draggable-resizable
// npm i vue-pdf-embed
// npm i pdf-lib
// npm i downloadjs
// [deprecated]npm i vue3-date-time-picker 
// ---- Replaced with npm i --save @vuepic/vue-datepicker@5.0.0 | https://v5.vue3datepicker.com/installation/
// npm i vue3-popper

// npm i vue-filepond filepond --save
// npm i filepond-plugin-file-validate-type --save
// npm i filepond-plugin-image-preview --save

// npm i vue-i18n@next
// npm i --save vue-drawing-canvas

// npm i pdfvuer@next --save (replace vue-pdf-embed due to blur when scale)

